<script>
import MainTitleBack from "@/components/helpers/MainTitleBack.vue";
import CertificateOrderForm from "@/components/documents/CertificateOrderForm.vue";

export default {
  components: { CertificateOrderForm, MainTitleBack },
};
</script>

<template>
  <div class="certificate-order default-content-padding_with-vertical">
    <MainTitleBack title="Заказ справки" href="Documents" />

    <CertificateOrderForm />
  </div>
</template>

<style lang="scss" scoped>
.certificate-order {
  background-color: $light-primary;
}
</style>
