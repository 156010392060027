<script>
import MainButtonIcon from "@/components/helpers/MainButtonIcon";
import vClickOutside from "v-click-outside";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },

  components: { MainButtonIcon },

  emits: ["update:value", "clear", "select", "clearResults", "input"],

  props: {
    value: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      default: "",
    },

    loading: {
      type: Boolean,
      default: false,
    },

    backgroundColor: {
      type: String,
      default: "#ffffff",
    },

    showIconSearch: {
      type: Boolean,
      default: true,
    },

    results: {
      type: Array,
      default: () => [],
    },

    error: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: String,
      default: "",
    },

    requiredIcon: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: "",
    },

    fieldType: {
      type: String,
      default: "input",
    },

    mask: {
      type: [String, Array, Object],
    },
  },

  watch: {
    value() {
      this.getResizeTextarea();
    },
  },

  computed: {
    showButtonClear() {
      return this.value.length > 0 && !this.loading;
    },
  },

  methods: {
    hideResults() {
      this.$emit("clearResults");
    },

    getResizeTextarea() {
      if (!!document.getElementById("search-textarea")) {
        this.$nextTick(() => {
          const target = document.getElementById("search-textarea");

          target.style.height = "auto";
          target.style.height = `${target.scrollHeight + 4}px`;
          //4px это высота двух бордеров, чтобы не прыгала высота компонента
        });
      }
    },
  },

  mounted() {
    const textarea = document.getElementById("search-textarea");

    if (this.value !== "" && !!textarea) {
      textarea.style.overflow = "auto";
    }
  },
};
</script>

<template>
  <div class="main-search-wrapper">
    <p>{{ label }} <span v-if="requiredIcon">*</span></p>

    <div
      v-click-outside="hideResults"
      class="main-search"
      :class="[
        { 'main-search_no-icon-search': !showIconSearch },
        { 'main-search_error': error },
      ]"
    >
      <span
        v-if="showIconSearch"
        class="main-search__icon-search icon-search"
      />

      <input
        v-if="fieldType === 'input'"
        v-maska="mask"
        type="text"
        :value="value"
        :placeholder="placeholder"
        :style="{ 'background-color': backgroundColor }"
        @input="$emit('input', $event.target.value)"
      />

      <textarea
        v-else
        id="search-textarea"
        v-maska="mask"
        :value="value"
        :placeholder="placeholder"
        :style="{ 'background-color': backgroundColor }"
        @input="$emit('input', $event.target.value)"
      />

      <MainButtonIcon
        v-if="showButtonClear"
        class="main-search__button-clear"
        :type="'button'"
        @click="$emit('clear')"
      >
        <span class="main-search__icon-close icon-close" />
      </MainButtonIcon>

      <span v-if="loading" class="main-search__icon-loading icon-loading" />

      <p v-if="error && errorMessage" class="main-search__error-message">
        {{ errorMessage }}
      </p>

      <div v-if="results.length > 0" class="main-search__results results">
        <div
          v-for="(result, index) in results"
          :key="index"
          class="results__item"
          @click="$emit('select', result)"
        >
          <p>{{ result }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-search-wrapper {
  > p {
    margin-bottom: $space-s;
    @include text-2;
    color: $dark-sixth;

    > span {
      color: $red;
    }
  }
}

.main-search {
  position: relative;
  width: 100%;

  input,
  textarea {
    width: 100%;
    border: 1px solid $light-fifth;
    border-radius: 8px;
    background-color: $light-second;
    outline: none;
    padding: 0 44px;
    height: 48px;
    color: $dark-primary;
    @include body-1;

    &::placeholder {
      color: $dark-sixth;
    }

    &:focus {
      border: 1px solid $gold-main;
    }
  }

  textarea {
    min-height: 82px;
    padding: 16px 40px 16px 16px !important;
    resize: none;
    height: fit-content;
  }

  &__icon-loading,
  &__icon-search {
    position: absolute;
    display: block;
  }

  &__button-clear {
    position: absolute;
    right: 16px;
    top: 14px;
  }

  &__icon-close {
    display: block;
    background-color: $dark-primary;
    width: 20px;
    height: 20px;
  }

  &__icon-search {
    left: 16px;
    top: 14px;
    background-color: $dark-sixth;
    width: 20px;
    height: 20px;
  }

  &__icon-loading {
    right: 16px;
    top: 14px;
    background-color: $dark-primary;
    width: 20px;
    height: 20px;
  }

  .results {
    position: absolute;
    padding: 16px;
    border-radius: 8px;
    background-color: $light-primary;
    box-shadow: 0 0 30px 0 rgba(169, 169, 169, 0.15);
    top: calc(100% + 16px);
    left: 0;
    width: 100%;
    z-index: 9999;
    max-height: 220px;
    overflow: auto;

    &__item {
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      > p {
        @include body-1;
        color: $dark-primary;
      }
    }
  }

  &__error-message {
    padding-left: 4px;
    margin-top: 8px;
    @include caption-1;
    color: $red;
  }

  &_no-icon-search {
    input,
    textarea {
      padding: 0 44px 0 16px;
    }
  }

  &_error {
    input,
    textarea {
      border: 1px solid $red;
    }
  }
}
</style>
