class UploadableFile {
  constructor(file) {
    this.file = file;
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
    this.url = URL.createObjectURL(file);
    this.status = null;
  }
}

export function addFiles(newFiles, files, options = {}) {
  const {
    MAX_FILES = 10, // Максимальное количество файлов по умолчанию
    MAX_TOTAL_SIZE = 5000000, // Максимальный общий размер файлов по умолчанию (здесь 5 Мб)
  } = options;

  const totalSize = files.reduce((acc, curr) => acc + curr.file.size, 0);
  const newSize = newFiles.reduce((acc, curr) => acc + curr.size, 0);

  if (files.length + newFiles.length > MAX_FILES) {
    throw new Error(
      `Превышено максимальное количество файлов. Максимум: ${MAX_FILES}`
    );
  }

  if (totalSize + newSize > MAX_TOTAL_SIZE) {
    throw new Error(
      `Превышен максимальный общий размер файлов. Максимум: ${MAX_TOTAL_SIZE} байт`
    );
  }

  const newUploadableFiles = newFiles
    .map((file) => new UploadableFile(file))
    .filter((file) => !fileExists(file.id, files));

  return [...files, ...newUploadableFiles];
}

export function removeFile(fileId, files) {
  return files.filter((file) => file.id !== fileId);
}

function fileExists(otherId, files) {
  return files.some(({ id }) => id === otherId);
}
