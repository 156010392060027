export const MASK_LETTERS_AND_SYMBOLS_PATTERN = {
  mask: "L*",
  tokens: { L: { pattern: /[а-яА-ЯёЁa-zA-Z-,.№ ]/ } },
};

export const MASK_NAMES_PATTERN = {
  mask: "L*",
  tokens: { L: { pattern: /[а-яА-ЯёЁa-zA-Z- ]/ } },
};

export const MASK_NUMBERS_PATTERN = {
  mask: "N*",
  tokens: { N: { pattern: /[0-9]/ } },
};

export const MASK_ADDRESS_PATTERN = {
  mask: "W*",
  tokens: { W: { pattern: /[а-яА-ЯёЁa-zA-Z0-9-.,()№ ]/ } },
};

export const MASK_CITY_PATTERN = {
  mask: "W*",
  tokens: { W: { pattern: /[а-яА-ЯёЁa-zA-Z0-9- ]/ } },
};
