<script>
import { addFiles, removeFile } from "@/package/helpers/file-manager";
import convertBytes from "@/package/helpers/convert-bytes";
import MainButton from "@/components/helpers/MainButton.vue";
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";
import { utcToZonedTimeWithOptions } from "date-fns-tz/fp";

export default {
  components: { MainButtonIcon, MainButton },

  props: {
    files: {
      type: Array,
      default: () => [],
    },

    error: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      MAX_FILES: 1, // Максимальное количество файлов
      MAX_TOTAL_SIZE: 10000000, // Максимальный общий размер файлов в байтах (здесь 5 МБ)
      isDragenter: false,
    };
  },

  methods: {
    convertBytes,

    handleDrop(event) {
      event.preventDefault();
      const files = Array.from(event.dataTransfer.files);
      this.uploadFiles(files);
    },

    handleFileChange(event) {
      const files = Array.from(event.target.files);
      this.uploadFiles(files);
    },

    uploadFiles(files) {
      try {
        const uploadedFiles = addFiles(files, this.files, {
          MAX_FILES: this.MAX_FILES,
          MAX_TOTAL_SIZE: this.MAX_TOTAL_SIZE,
        });

        this.$emit("update:files", uploadedFiles);
        this.resetInput();
      } catch (error) {
        console.error(error.message);
      }
    },

    openFileInput() {
      this.$refs.fileInput.click();
    },

    removeFile(fileId) {
      const uploadedFiles = removeFile(fileId, this.files);

      this.$emit("update:files", uploadedFiles);
    },

    resetInput() {
      this.$refs.fileInput.value = "";
    },
  },
};
</script>

<template>
  <div class="main-drop-zone">
    <div
      class="main-drop-zone__wrapper"
      :class="{ 'main-drop-zone__wrapper_with-files': files.length > 0 }"
      @dragover.prevent
      @drop="handleDrop"
    >
      <template v-if="files.length === 0">
        <span class="main-drop-zone__icon">
          <span class="icon-cloud-thin" />
        </span>

        <p class="main-drop-zone__text">
          Перетащите или
          <MainButton
            title="загрузите файлы"
            color="blue"
            @click="openFileInput"
          />
        </p>

        <p class="main-drop-zone__caption">PNG, JPG, PDF — 1 файл до 10 Мб</p>
      </template>

      <template v-else>
        <ul v-if="files.length > 0">
          <li v-for="file in files" :key="file.id">
            <div class="main-drop-zone__file file">
              <div class="file__content">
                <span class="icon-file-thin" />

                <div class="file__info">
                  <p>{{ file.file.name }}</p>
                  <span>{{ convertBytes(file.file.size) }}</span>
                </div>

                <div class="file__buttons">
                  <MainButtonIcon v-if="false">
                    <span class="icon-download-thin" />
                  </MainButtonIcon>
                  <MainButtonIcon @click="removeFile(file.id)">
                    <span class="icon-trash-thin" />
                  </MainButtonIcon>
                  <MainButtonIcon v-if="false">
                    <span class="icon-close-thin" />
                  </MainButtonIcon>
                </div>
              </div>
              <div v-if="false" class="file__progress progress">
                <span class="progress__bar">
                  <span></span>
                </span>

                <p class="progress__value">50%</p>
              </div>
            </div>
          </li>
        </ul>
      </template>
    </div>

    <p v-if="error" class="main-drop-zone__error">{{ errorMessage }}</p>

    <input
      ref="fileInput"
      type="file"
      style="display: none"
      accept="image/png, image/jpg, image/jpeg"
      multiple
      @change="handleFileChange"
    />
  </div>
</template>

<style lang="scss" scoped>
.main-drop-zone {
  &__wrapper {
    border-radius: 8px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%232121211A' stroke-width='4' stroke-dasharray='6' stroke-dashoffset='2' stroke-linecap='round'/%3e%3c/svg%3e");
    background-color: $light-primary;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_with-files {
      padding: 16px;
      background-image: none;
      border: 1px solid $light-third;
    }
  }

  &__error {
    margin-top: 8px;
    padding: 0 4px;
    @include caption-1;
    color: $red;
  }

  &__icon {
    width: 36px;
    height: 36px;
    border-radius: 6px;
    background: $light-second;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;

    .icon-cloud-thin {
      width: 20px;
      height: 20px;
      background-color: $dark-primary;
    }
  }

  &__text {
    @include text-2;
    color: $dark-primary;
    margin-bottom: 2px;

    .main-button {
      display: inline-block;
    }
  }

  &__caption {
    @include caption-1;
    color: $dark-sixth;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
  }

  .file {
    width: 100%;

    .icon-file-thin {
      min-width: 40px;
      width: 40px;
      height: 40px;
      background-color: $dark-sixth;
    }

    .icon-download-thin,
    .icon-trash-thin,
    .icon-close-thin {
      width: 20px;
      height: 20px;
      background-color: $dark-primary;
    }

    &__content {
      display: flex;
      justify-content: flex-start;
      gap: 8px;
    }

    &__info {
      width: 100%;

      > p {
        @include text-2;
        color: $dark-primary;
      }

      > span {
        @include caption-1;
        color: $dark-sixth;
      }
    }

    &__buttons {
      display: flex;
      gap: 8px;
    }
  }

  .progress {
    display: flex;
    align-items: center;
    gap: 12px;
    padding-left: 48px;
    margin-top: 4px;

    &__bar {
      width: 100%;
      height: 8px;
      background-color: $light-second;
      border-radius: 8px;

      > span {
        display: block;
        width: 50%;
        height: 8px;
        border-radius: 8px;
        background-color: $gold-main;
      }
    }

    &__value {
      @include text-2;
      color: $dark-sixth;
    }
  }
}
</style>
